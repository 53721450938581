.status-card {
    img {
      width: 100%;
      border-radius: 8px;
      height: 128px;
    }
    width: 212.03px;
    height: 310px;
    box-shadow: 0px 4px 24px 0px #e0f0ff;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px 13px;
    .ViewDetail {
      padding: 10px 9px;
      background: #f3f9ff;
      border-radius: 5px;
      h5 {
        line-height: normal !important;
      }
    }
  }
  .order-status {
    padding: 6px 9px;
    border-radius: 5px !important ;
  
    font-size: 11px;
    font-weight: 400;
    // max-width: 90px;
    display: flex;
    align-items: center;
    color: #15640f;
      background: #d5ffd4;
      border: 1px solid #d5ffd4;
    svg {
      height: 15px !important;
      width: 15px !important;
      margin-left: 5px;
    }
  }
  .MuiStepLabel-iconContainer {
    width: 25px;
    height: 25px;
    justify-content: center;
  }
  