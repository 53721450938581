
@font-face {
    font-family: "KiaSignatureLight";
    font-style: normal;
    font-weight: 300;
    src:
      local("KiaSignatureLight"),
      url("../assets/fonts/Kia_Signature/KiaSignature/OTF/KiaSignatureOTFLight.otf") format("opentype") ,
      url("../assets/fonts/Kia_Signature/KiaSignature/TTF/KiaSignatureLight.ttf") format("truetype"),
      url("../assets/fonts/Kia_Signature/KiaSignature/Webfonts/eot/KiaSignatureLight.eot") format("eot"),
      url("../assets/fonts/Kia_Signature/KiaSignature/Webfonts/woff/KiaSignatureLight.woff") format("woff"),
      url("../assets/fonts/Kia_Signature/KiaSignature/Webfonts/woff2/KiaSignatureLight.woff2") format("woff2");
}

@font-face {
    font-family: "KiaSignatureRegular";
    font-style: normal;
    font-weight: 400;
    src:
      local("KiaSignatureRegular"),
      url("../assets/fonts/Kia_Signature/KiaSignature/OTF/KiaSignatureOTFRegular.otf") format("opentype") ,
      url("../assets/fonts/Kia_Signature/KiaSignature/TTF/KiaSignatureRegular.ttf") format("truetype"),
      url("../assets/fonts/Kia_Signature/KiaSignature/Webfonts/eot/KiaSignatureRegular.eot") format("eot"),
      url("../assets/fonts/Kia_Signature/KiaSignature/Webfonts/woff/KiaSignatureRegular.woff") format("woff"),
      url("../assets/fonts/Kia_Signature/KiaSignature/Webfonts/woff2/KiaSignatureRegular.woff2") format("woff2");
}

@font-face {
    font-family: "KiaSignatureBold";
    font-style: normal;
    font-weight: 700;
    src:
      local("KiaSignatureBold"),
      url("../assets/fonts/Kia_Signature/KiaSignature/OTF/KiaSignatureOTFBold.otf") format("opentype") ,
      url("../assets/fonts/Kia_Signature/KiaSignature/TTF/KiaSignatureBold.ttf") format("truetype"),
      url("../assets/fonts/Kia_Signature/KiaSignature/Webfonts/eot/KiaSignatureBold.eot") format("eot"),
      url("../assets/fonts/Kia_Signature/KiaSignature/Webfonts/woff/KiaSignatureBold.woff") format("woff"),
      url("../assets/fonts/Kia_Signature/KiaSignature/Webfonts/woff2/KiaSignatureBold.woff2") format("woff2");
}