.filtersLabel{
    font-size:12px;
    color:#93A3AE;
    vertical-align:bottom;
     margin-left:5px;
    font-family:"KiaSignatureBold" !important;
}

.filterField{
    color: #05141F !important;
    // background :#f2f6fc;
    font-size: 13px !important;
    font-family: "KiaSignatureRegular" !important;
    border-bottom:1px solid #1C1C1C4D !important;
    &:before{
         border-bottom:0px !important;
    }

}

.filterField .MuiInputBase-root.MuiInput-root{
    &:before{
        border-bottom:0px !important;
   }
}