.complex-custom-table{
    .px-0{
        padding-inline: 0px !important;
    }
    box-shadow: 0px 4px 4px 2px #DEECFB !important;
    .MuiTableRow-root:nth-of-type(odd) {
        background-color: #ffffff !important;
    }
    .MuiTableRow-root:nth-of-type(even) {
        background-color: #ffffff !important;
    }
    .delete-btn{
        padding: 8px 0px !important;
    }
    .update-btn{
        padding: 4px 0px !important;
        background: rgb(98, 118, 133 , .43);
        font-size: 8px !important;
        color: #ffffff !important;
    }
    .inner-table{
        h4{
            justify-content: center;
            padding: 6px 0px;
            height: 43px !important;
            display: flex;
            gap: 4px;
            span{
                margin: auto;
            }
        }
        h4:nth-of-type(odd) {
            background-color: #ffffff !important;
        }
        h4:nth-of-type(even) {
             background-color: #F0F8FF !important;
         }
        } 
        .subtotal{
            padding-top: 10px;
            height: 14px;
            border-radius: 5px 0px 0px 0px;
            // background: #DFECF9 !important;
            h4{
             margin: auto !important;
            }
        }
        .total{
           height: 53px;
           border-radius: 5px;
           opacity: 0px;
           background: #DFECF9 !important;
           h4{
            margin: auto !important;
           }
        }
    td{
        padding: 10px !important;
        border-right: 1px solid #D1E0EC !important;
        border-bottom: 1px solid #D1E0EC !important;
    }
    td.pb-0{
        padding-bottom: 0px !important;
    }
    td.pt-0{
        padding-top: 0px !important;
    }
    .border-bottom-none{
        border-bottom: none !important;
    }
    .border-right-none{
        border-right: none !important;
    }
    .checkinputfield{
        input{
            padding: 14.2px 14px !important;
        }
        fieldset{
            top: 0px !important;
            border-color: #ABC8DE !important;
            border-radius: 7px !important;
        }
    }
}