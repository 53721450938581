button{
    border-radius:7px !important;
     &.small{
        height :43px;
        font-size:12px;
     }
     &.btnOutlinedLg{
         font-size:14px;
         line-height: 16.8px;
         height:58px;
         padding:13px 20px;
         border:1px solid #B7C6D5;
         border-radius:7px;
         font-family: "KiaSignatureBold" !important;
     }
     &.medium{
        padding: 10px 26px;
        line-height: 29px;
        font-size: 14px;
        font-weight: 400;
        box-sizing: border-box;
        height: auto;
        font-family: 'KiaSignatureRegular' !important;
     }
     &.large{
        font-size: 17px;
        font-weight: 600;
        line-height: 29px;
        padding: 14.2px 60px;
        min-width: 208px;
     }
     &.download{
        background: #D7EDFE;
        border: 1px solid #B2D3ED;
        color: #05141F;
        font-size:14px;
        font-weight:600;
        padding: 9.34px 26px;
        line-height: 29px;
        box-sizing: border-box;
        height: auto;
        font-family: 'KiaSignatureRegular' !important;
        svg{
            margin-right: 8px;
        }
        &:hover{
            background:#000;
            color:#ffffff;
            svg {
                path {
                  fill: white !important;
                }
              }
        }
     }
}
a{
    &.small{
        height :43px;
        font-size:12px;
     }
    &.btnOutlinedLg{
        font-size:14px;
        line-height: 16.8px;
        height:58px;
        padding:13px 20px;
        border:1px solid #B7C6D5;
        border-radius:7px;
        font-family: "KiaSignatureBold" !important;
    }
}

.attachmentBtn{
     border-radius:117px;
     padding:9px 26px;
     border:1px solid #A9BBCC;
     background:#ffffff;
     font-size: 14px !important;
     color:#68798A;
     display:inline-flex;
     align-items:center;
     margin-top:13px !important;
     margin-right:10px !important;
     font-family: "KiaSignatureRegular" !important;
     cursor: pointer;
     svg{
         margin-left:30px;
     }
     &:last-child{
         margin-right:0px;
     }
}

.arrowBack{
    height:12px;
    width:7px;
    display:inline-block;
    background:url(../assets/img/arrow-back.svg) no-repeat 0px 0px;
    cursor:pointer;
    margin:10px 0px;
}