.popup-input{
    input{
        padding:13.72px !important;
    }
}
.popup-select{
    .MuiSelect-select {
        padding:13.72px !important;
    }
}

.popup{
    .MuiCardContent-root{
        overflow: auto;
        max-height: 350px!important;
        height: 80% !important;
    }
}
.popup.nooverflow{
    .MuiCardContent-root{
        overflow: hidden !important;
    }
}