    .customModal>.css-4pb9x1-MuiBackdrop-root-MuiModal-backdrop {
        background-color: rgba(5, 20, 31, 0.9) !important;
        backdrop-filter: blur(10px);
    }
    .customModalBig{
        .customModal_md {
            max-width: 1130px !important;
            width: 80% !important;
        }
    
    }

    .customModal_md {
        width: 100%;
        max-width: 665px;
        @media screen and (max-width: 768px) {
            width: 80%;
        }
    }
    .customModal_sm {
        width: 100%;
        max-width: 500px;
        @media screen and (max-width: 768px) {
            width: 80%;
        }
    }

    .title {
        font-size: 25px !important;
        font-weight: 600;
    }

    .subtitle {
        font-size: 15px !important;
    }
    
    .billingmodal{
        max-width: 910px !important;
    }