@mixin body{
    position: absolute;
    top: 12%;
    z-index: -1;
    min-height: 88%;
    transition-duration: .2s
}

.open{
    @include body();
    left: 24%;
    width: 74%;
    padding-bottom: 20px;
}

.close{
    @include body();
    left: 6%;
    width: 92%;
}

@media(max-width: 600px){
    .open{
        width: 99% !important;
        margin: auto;
        left: .5% !important;
    }
}