@mixin loginMixin {
    display: flex;
    align-items: center;
    justify-content: center;
}


.login {
    @include loginMixin;
    align-items: start;
    background: #fff url(../assets/img/tyreImg.png) no-repeat bottom;
    background-size: 80% auto;
    width: 100%;
    padding: 90px 80px 40px;
    box-sizing: border-box;
    height: 100%;
}   

.grayLink{
    color: #9D9D9D;
    font-size: 14px !important;
    &:hover{
        color: #333;
        cursor: pointer;
    }
}


.imgSec {
    @include loginMixin;
    height: 100vh;
    overflow: hidden;
    img{ 
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(1);
    }
}
