 .fieldsetLabel{
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #05141F !important;
 }

 .prevEditTextarea{
   border: none;
   display:block;
   width:100%;
   resize:none;
   margin-top:20px;
   &:focus{
       outline:none;
   }
 }

 #previewBody{
   min-height:75px;
   height:auto;
}

.checkboxGroup{
    display:flex;
    flex-direction: row !important;
    .options{
        width:20%;
        @media (max-width:767px){
            width:50%;
        }
    }
}

.smallInputField{
    input{
        height:41px;
        font-size:14px;
        box-sizing: border-box;
    }
}



.datePickerSmall{
     input{
        height:42px;
        font-size:14px;
        box-sizing: border-box;
     }
}

.selectMedium{
    height:50px;
    font-size: 15px;
    min-width: 120px;
    flex-grow: 1;
    margin: 0;
    background: #fff;
    width:100%;
}