.card {
    border: 1px solid #A0BFD6;
    padding: 5px !important;
    border-radius: 10px !important;
    height:320px;
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.cards {
    border: 1px solid #A0BFD6;
    padding: 5px !important;
    border-radius: 10px !important;
    height: calc(100% - 10px);
    
}

.profileSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #E7F2FC !important;
    border-radius: 10px;
    flex-grow: 1;
    height: 100%;
    & img {
        border-radius: 100%;
        height: auto;
    }
}

.uploadicon {
    height: 40px;
    display: flex !important;
    align-items: center !important;
    background: #fff !important;
    cursor: pointer;

    label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
.profileImg{
    height: 150px;
    width: 150px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    object-fit: cover;
}
.profileInfo{
    padding: 15px 10px;
    text-align: center;
}
.profileName{
    font-size: 18px;
    font-family: "KiaSignatureRegular" !important;
    color: #000;
}

.profilePosition{
    font-size: 15px;
    font-family: "KiaSignatureRegular" !important;
    color: rgba(5, 20, 31, 0.6);
}

.profilePicCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:none;
    border-left:1px solid #E2EDF8;
    box-shadow:none;
}